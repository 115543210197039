html, body {
  margin: 0;
  padding: 0;
  background-color: #191416;
}

body {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.swarm {
  position: absolute;
}

.bees-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}

.bees {
  display: block;
  transition: transform 0.5s linear;
}

.figure {
  position: relative;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  background-position: center;
  background-size: cover;
}

.figure .background {
  width: 100%;
  height: 100%;
}

.help {
  font-family: Courier, "Courier New", monospace;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #FFACAB;
  text-shadow: -4px 4px #ACABFF, 2px 2px red;
  transform: rotate(-3deg);
  position: absolute;
  bottom: 0;
  right: 0;
}
